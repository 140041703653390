import * as React from "react";

function QRcode(props) {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    id="map"
    viewBox="0 0 231 231"
    width="1022"
    height="1022"
    fill="#ffffff"
    data-dot="7"
  >
    <rect x="0" y="0" height="231" width="231" />
    <defs />
    <g fill="#000000">
      <rect
        x="28"
        y="182"
        width="21"
        height="21"
        transform="rotate(-90,38.5,192.5)"
      />
      <path
        clip-rule="evenodd"
        class="svgeye"
        d="M 14 168v 49h 49v -49zM 21 175h 35v 35h -35z"
        transform="rotate(-90,38.5,192.5)"
      />
      <rect
        x="182"
        y="28"
        width="21"
        height="21"
        transform="rotate(90,192.5,38.5)"
      />
      <path
        clip-rule="evenodd"
        class="svgeye"
        d="M 168 14v 49h 49v -49zM 175 21h 35v 35h -35z"
        transform="rotate(90,192.5,38.5)"
      />
      <rect
        x="28"
        y="28"
        width="21"
        height="21"
        transform="rotate(0,38.5,38.5)"
      />
      <path
        clip-rule="evenodd"
        class="svgeye"
        d="M 14 14v 49h 49v -49zM 21 21h 35v 35h -35z"
        transform="rotate(0,38.5,38.5)"
      />
      <rect x="77" y="14" width="7" height="7" transform="rotate(0,80.5,17.5)" />
      <rect
        x="112"
        y="14"
        width="7"
        height="7"
        transform="rotate(0,115.5,17.5)"
      />
      <rect x="70" y="21" width="7" height="7" transform="rotate(0,73.5,24.5)" />
      <rect x="77" y="21" width="7" height="7" transform="rotate(0,80.5,24.5)" />
      <rect x="98" y="21" width="7" height="7" transform="rotate(0,101.5,24.5)" />
      <rect
        x="105"
        y="21"
        width="7"
        height="7"
        transform="rotate(0,108.5,24.5)"
      />
      <rect
        x="119"
        y="21"
        width="7"
        height="7"
        transform="rotate(0,122.5,24.5)"
      />
      <rect
        x="133"
        y="21"
        width="7"
        height="7"
        transform="rotate(0,136.5,24.5)"
      />
      <rect
        x="140"
        y="21"
        width="7"
        height="7"
        transform="rotate(0,143.5,24.5)"
      />
      <rect x="70" y="28" width="7" height="7" transform="rotate(0,73.5,31.5)" />
      <rect x="77" y="28" width="7" height="7" transform="rotate(0,80.5,31.5)" />
      <rect x="84" y="28" width="7" height="7" transform="rotate(0,87.5,31.5)" />
      <rect x="98" y="28" width="7" height="7" transform="rotate(0,101.5,31.5)" />
      <rect
        x="105"
        y="28"
        width="7"
        height="7"
        transform="rotate(0,108.5,31.5)"
      />
      <rect
        x="112"
        y="28"
        width="7"
        height="7"
        transform="rotate(0,115.5,31.5)"
      />
      <rect
        x="126"
        y="28"
        width="7"
        height="7"
        transform="rotate(0,129.5,31.5)"
      />
      <rect
        x="147"
        y="28"
        width="7"
        height="7"
        transform="rotate(0,150.5,31.5)"
      />
      <rect x="84" y="35" width="7" height="7" transform="rotate(0,87.5,38.5)" />
      <rect x="91" y="35" width="7" height="7" transform="rotate(0,94.5,38.5)" />
      <rect x="98" y="35" width="7" height="7" transform="rotate(0,101.5,38.5)" />
      <rect
        x="112"
        y="35"
        width="7"
        height="7"
        transform="rotate(0,115.5,38.5)"
      />
      <rect
        x="133"
        y="35"
        width="7"
        height="7"
        transform="rotate(0,136.5,38.5)"
      />
      <rect
        x="154"
        y="35"
        width="7"
        height="7"
        transform="rotate(0,157.5,38.5)"
      />
      <rect x="77" y="42" width="7" height="7" transform="rotate(0,80.5,45.5)" />
      <rect x="84" y="42" width="7" height="7" transform="rotate(0,87.5,45.5)" />
      <rect x="91" y="42" width="7" height="7" transform="rotate(0,94.5,45.5)" />
      <rect x="98" y="42" width="7" height="7" transform="rotate(0,101.5,45.5)" />
      <rect
        x="119"
        y="42"
        width="7"
        height="7"
        transform="rotate(0,122.5,45.5)"
      />
      <rect
        x="126"
        y="42"
        width="7"
        height="7"
        transform="rotate(0,129.5,45.5)"
      />
      <rect
        x="147"
        y="42"
        width="7"
        height="7"
        transform="rotate(0,150.5,45.5)"
      />
      <rect x="84" y="49" width="7" height="7" transform="rotate(0,87.5,52.5)" />
      <rect
        x="119"
        y="49"
        width="7"
        height="7"
        transform="rotate(0,122.5,52.5)"
      />
      <rect
        x="133"
        y="49"
        width="7"
        height="7"
        transform="rotate(0,136.5,52.5)"
      />
      <rect
        x="140"
        y="49"
        width="7"
        height="7"
        transform="rotate(0,143.5,52.5)"
      />
      <rect
        x="147"
        y="49"
        width="7"
        height="7"
        transform="rotate(0,150.5,52.5)"
      />
      <rect
        x="154"
        y="49"
        width="7"
        height="7"
        transform="rotate(0,157.5,52.5)"
      />
      <rect x="70" y="56" width="7" height="7" transform="rotate(0,73.5,59.5)" />
      <rect x="84" y="56" width="7" height="7" transform="rotate(0,87.5,59.5)" />
      <rect x="98" y="56" width="7" height="7" transform="rotate(0,101.5,59.5)" />
      <rect
        x="112"
        y="56"
        width="7"
        height="7"
        transform="rotate(0,115.5,59.5)"
      />
      <rect
        x="126"
        y="56"
        width="7"
        height="7"
        transform="rotate(0,129.5,59.5)"
      />
      <rect
        x="140"
        y="56"
        width="7"
        height="7"
        transform="rotate(0,143.5,59.5)"
      />
      <rect
        x="154"
        y="56"
        width="7"
        height="7"
        transform="rotate(0,157.5,59.5)"
      />
      <rect x="77" y="63" width="7" height="7" transform="rotate(0,80.5,66.5)" />
      <rect x="91" y="63" width="7" height="7" transform="rotate(0,94.5,66.5)" />
      <rect x="98" y="63" width="7" height="7" transform="rotate(0,101.5,66.5)" />
      <rect
        x="112"
        y="63"
        width="7"
        height="7"
        transform="rotate(0,115.5,66.5)"
      />
      <rect
        x="119"
        y="63"
        width="7"
        height="7"
        transform="rotate(0,122.5,66.5)"
      />
      <rect
        x="154"
        y="63"
        width="7"
        height="7"
        transform="rotate(0,157.5,66.5)"
      />
      <rect x="21" y="70" width="7" height="7" transform="rotate(0,24.5,73.5)" />
      <rect x="28" y="70" width="7" height="7" transform="rotate(0,31.5,73.5)" />
      <rect x="35" y="70" width="7" height="7" transform="rotate(0,38.5,73.5)" />
      <rect x="49" y="70" width="7" height="7" transform="rotate(0,52.5,73.5)" />
      <rect x="56" y="70" width="7" height="7" transform="rotate(0,59.5,73.5)" />
      <rect x="77" y="70" width="7" height="7" transform="rotate(0,80.5,73.5)" />
      <rect x="91" y="70" width="7" height="7" transform="rotate(0,94.5,73.5)" />
      <rect
        x="105"
        y="70"
        width="7"
        height="7"
        transform="rotate(0,108.5,73.5)"
      />
      <rect
        x="126"
        y="70"
        width="7"
        height="7"
        transform="rotate(0,129.5,73.5)"
      />
      <rect
        x="140"
        y="70"
        width="7"
        height="7"
        transform="rotate(0,143.5,73.5)"
      />
      <rect
        x="147"
        y="70"
        width="7"
        height="7"
        transform="rotate(0,150.5,73.5)"
      />
      <rect
        x="196"
        y="70"
        width="7"
        height="7"
        transform="rotate(0,199.5,73.5)"
      />
      <rect
        x="203"
        y="70"
        width="7"
        height="7"
        transform="rotate(0,206.5,73.5)"
      />
      <rect x="14" y="77" width="7" height="7" transform="rotate(0,17.5,80.5)" />
      <rect x="21" y="77" width="7" height="7" transform="rotate(0,24.5,80.5)" />
      <rect x="35" y="77" width="7" height="7" transform="rotate(0,38.5,80.5)" />
      <rect x="42" y="77" width="7" height="7" transform="rotate(0,45.5,80.5)" />
      <rect x="63" y="77" width="7" height="7" transform="rotate(0,66.5,80.5)" />
      <rect x="77" y="77" width="7" height="7" transform="rotate(0,80.5,80.5)" />
      <rect
        x="119"
        y="77"
        width="7"
        height="7"
        transform="rotate(0,122.5,80.5)"
      />
      <rect
        x="140"
        y="77"
        width="7"
        height="7"
        transform="rotate(0,143.5,80.5)"
      />
      <rect
        x="154"
        y="77"
        width="7"
        height="7"
        transform="rotate(0,157.5,80.5)"
      />
      <rect
        x="161"
        y="77"
        width="7"
        height="7"
        transform="rotate(0,164.5,80.5)"
      />
      <rect
        x="175"
        y="77"
        width="7"
        height="7"
        transform="rotate(0,178.5,80.5)"
      />
      <rect
        x="210"
        y="77"
        width="7"
        height="7"
        transform="rotate(0,213.5,80.5)"
      />
      <rect x="28" y="84" width="7" height="7" transform="rotate(0,31.5,87.5)" />
      <rect x="35" y="84" width="7" height="7" transform="rotate(0,38.5,87.5)" />
      <rect x="42" y="84" width="7" height="7" transform="rotate(0,45.5,87.5)" />
      <rect x="56" y="84" width="7" height="7" transform="rotate(0,59.5,87.5)" />
      <rect x="70" y="84" width="7" height="7" transform="rotate(0,73.5,87.5)" />
      <rect x="77" y="84" width="7" height="7" transform="rotate(0,80.5,87.5)" />
      <rect x="84" y="84" width="7" height="7" transform="rotate(0,87.5,87.5)" />
      <rect x="91" y="84" width="7" height="7" transform="rotate(0,94.5,87.5)" />
      <rect x="98" y="84" width="7" height="7" transform="rotate(0,101.5,87.5)" />
      <rect
        x="126"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,129.5,87.5)"
      />
      <rect
        x="133"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,136.5,87.5)"
      />
      <rect
        x="147"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,150.5,87.5)"
      />
      <rect
        x="154"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,157.5,87.5)"
      />
      <rect
        x="168"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,171.5,87.5)"
      />
      <rect
        x="175"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,178.5,87.5)"
      />
      <rect
        x="182"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,185.5,87.5)"
      />
      <rect
        x="189"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,192.5,87.5)"
      />
      <rect
        x="203"
        y="84"
        width="7"
        height="7"
        transform="rotate(0,206.5,87.5)"
      />
      <rect x="14" y="91" width="7" height="7" transform="rotate(0,17.5,94.5)" />
      <rect x="21" y="91" width="7" height="7" transform="rotate(0,24.5,94.5)" />
      <rect x="42" y="91" width="7" height="7" transform="rotate(0,45.5,94.5)" />
      <rect x="63" y="91" width="7" height="7" transform="rotate(0,66.5,94.5)" />
      <rect x="77" y="91" width="7" height="7" transform="rotate(0,80.5,94.5)" />
      <rect x="91" y="91" width="7" height="7" transform="rotate(0,94.5,94.5)" />
      <rect x="98" y="91" width="7" height="7" transform="rotate(0,101.5,94.5)" />
      <rect
        x="112"
        y="91"
        width="7"
        height="7"
        transform="rotate(0,115.5,94.5)"
      />
      <rect
        x="140"
        y="91"
        width="7"
        height="7"
        transform="rotate(0,143.5,94.5)"
      />
      <rect
        x="154"
        y="91"
        width="7"
        height="7"
        transform="rotate(0,157.5,94.5)"
      />
      <rect
        x="175"
        y="91"
        width="7"
        height="7"
        transform="rotate(0,178.5,94.5)"
      />
      <rect
        x="182"
        y="91"
        width="7"
        height="7"
        transform="rotate(0,185.5,94.5)"
      />
      <rect
        x="210"
        y="91"
        width="7"
        height="7"
        transform="rotate(0,213.5,94.5)"
      />
      <rect x="21" y="98" width="7" height="7" transform="rotate(0,24.5,101.5)" />
      <rect x="35" y="98" width="7" height="7" transform="rotate(0,38.5,101.5)" />
      <rect x="49" y="98" width="7" height="7" transform="rotate(0,52.5,101.5)" />
      <rect x="56" y="98" width="7" height="7" transform="rotate(0,59.5,101.5)" />
      <rect x="70" y="98" width="7" height="7" transform="rotate(0,73.5,101.5)" />
      <rect x="84" y="98" width="7" height="7" transform="rotate(0,87.5,101.5)" />
      <rect
        x="119"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,122.5,101.5)"
      />
      <rect
        x="133"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,136.5,101.5)"
      />
      <rect
        x="147"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,150.5,101.5)"
      />
      <rect
        x="161"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,164.5,101.5)"
      />
      <rect
        x="175"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,178.5,101.5)"
      />
      <rect
        x="196"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,199.5,101.5)"
      />
      <rect
        x="203"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,206.5,101.5)"
      />
      <rect
        x="210"
        y="98"
        width="7"
        height="7"
        transform="rotate(0,213.5,101.5)"
      />
      <rect
        x="14"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,17.5,108.5)"
      />
      <rect
        x="28"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,31.5,108.5)"
      />
      <rect
        x="84"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,87.5,108.5)"
      />
      <rect
        x="91"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,94.5,108.5)"
      />
      <rect
        x="105"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,108.5,108.5)"
      />
      <rect
        x="119"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,122.5,108.5)"
      />
      <rect
        x="133"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,136.5,108.5)"
      />
      <rect
        x="140"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,143.5,108.5)"
      />
      <rect
        x="147"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,150.5,108.5)"
      />
      <rect
        x="168"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,171.5,108.5)"
      />
      <rect
        x="175"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,178.5,108.5)"
      />
      <rect
        x="189"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,192.5,108.5)"
      />
      <rect
        x="196"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,199.5,108.5)"
      />
      <rect
        x="203"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,206.5,108.5)"
      />
      <rect
        x="210"
        y="105"
        width="7"
        height="7"
        transform="rotate(0,213.5,108.5)"
      />
      <rect
        x="28"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,31.5,115.5)"
      />
      <rect
        x="35"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,38.5,115.5)"
      />
      <rect
        x="42"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,45.5,115.5)"
      />
      <rect
        x="56"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,59.5,115.5)"
      />
      <rect
        x="84"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,87.5,115.5)"
      />
      <rect
        x="91"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,94.5,115.5)"
      />
      <rect
        x="98"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,101.5,115.5)"
      />
      <rect
        x="105"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,108.5,115.5)"
      />
      <rect
        x="112"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,115.5,115.5)"
      />
      <rect
        x="119"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,122.5,115.5)"
      />
      <rect
        x="147"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,150.5,115.5)"
      />
      <rect
        x="168"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,171.5,115.5)"
      />
      <rect
        x="175"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,178.5,115.5)"
      />
      <rect
        x="182"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,185.5,115.5)"
      />
      <rect
        x="189"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,192.5,115.5)"
      />
      <rect
        x="203"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,206.5,115.5)"
      />
      <rect
        x="210"
        y="112"
        width="7"
        height="7"
        transform="rotate(0,213.5,115.5)"
      />
      <rect
        x="77"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,80.5,122.5)"
      />
      <rect
        x="84"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,87.5,122.5)"
      />
      <rect
        x="91"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,94.5,122.5)"
      />
      <rect
        x="105"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,108.5,122.5)"
      />
      <rect
        x="119"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,122.5,122.5)"
      />
      <rect
        x="154"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,157.5,122.5)"
      />
      <rect
        x="182"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,185.5,122.5)"
      />
      <rect
        x="189"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,192.5,122.5)"
      />
      <rect
        x="203"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,206.5,122.5)"
      />
      <rect
        x="210"
        y="119"
        width="7"
        height="7"
        transform="rotate(0,213.5,122.5)"
      />
      <rect
        x="28"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,31.5,129.5)"
      />
      <rect
        x="35"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,38.5,129.5)"
      />
      <rect
        x="42"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,45.5,129.5)"
      />
      <rect
        x="49"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,52.5,129.5)"
      />
      <rect
        x="56"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,59.5,129.5)"
      />
      <rect
        x="70"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,73.5,129.5)"
      />
      <rect
        x="91"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,94.5,129.5)"
      />
      <rect
        x="105"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,108.5,129.5)"
      />
      <rect
        x="119"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,122.5,129.5)"
      />
      <rect
        x="133"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,136.5,129.5)"
      />
      <rect
        x="175"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,178.5,129.5)"
      />
      <rect
        x="189"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,192.5,129.5)"
      />
      <rect
        x="203"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,206.5,129.5)"
      />
      <rect
        x="210"
        y="126"
        width="7"
        height="7"
        transform="rotate(0,213.5,129.5)"
      />
      <rect
        x="28"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,31.5,136.5)"
      />
      <rect
        x="63"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,66.5,136.5)"
      />
      <rect
        x="84"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,87.5,136.5)"
      />
      <rect
        x="91"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,94.5,136.5)"
      />
      <rect
        x="105"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,108.5,136.5)"
      />
      <rect
        x="140"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,143.5,136.5)"
      />
      <rect
        x="147"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,150.5,136.5)"
      />
      <rect
        x="161"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,164.5,136.5)"
      />
      <rect
        x="168"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,171.5,136.5)"
      />
      <rect
        x="189"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,192.5,136.5)"
      />
      <rect
        x="196"
        y="133"
        width="7"
        height="7"
        transform="rotate(0,199.5,136.5)"
      />
      <rect
        x="14"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,17.5,143.5)"
      />
      <rect
        x="35"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,38.5,143.5)"
      />
      <rect
        x="56"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,59.5,143.5)"
      />
      <rect
        x="77"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,80.5,143.5)"
      />
      <rect
        x="84"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,87.5,143.5)"
      />
      <rect
        x="91"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,94.5,143.5)"
      />
      <rect
        x="112"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,115.5,143.5)"
      />
      <rect
        x="140"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,143.5,143.5)"
      />
      <rect
        x="154"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,157.5,143.5)"
      />
      <rect
        x="168"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,171.5,143.5)"
      />
      <rect
        x="175"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,178.5,143.5)"
      />
      <rect
        x="182"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,185.5,143.5)"
      />
      <rect
        x="189"
        y="140"
        width="7"
        height="7"
        transform="rotate(0,192.5,143.5)"
      />
      <rect
        x="28"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,31.5,150.5)"
      />
      <rect
        x="35"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,38.5,150.5)"
      />
      <rect
        x="63"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,66.5,150.5)"
      />
      <rect
        x="70"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,73.5,150.5)"
      />
      <rect
        x="77"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,80.5,150.5)"
      />
      <rect
        x="84"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,87.5,150.5)"
      />
      <rect
        x="98"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,101.5,150.5)"
      />
      <rect
        x="112"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,115.5,150.5)"
      />
      <rect
        x="119"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,122.5,150.5)"
      />
      <rect
        x="126"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,129.5,150.5)"
      />
      <rect
        x="133"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,136.5,150.5)"
      />
      <rect
        x="154"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,157.5,150.5)"
      />
      <rect
        x="161"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,164.5,150.5)"
      />
      <rect
        x="168"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,171.5,150.5)"
      />
      <rect
        x="189"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,192.5,150.5)"
      />
      <rect
        x="196"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,199.5,150.5)"
      />
      <rect
        x="203"
        y="147"
        width="7"
        height="7"
        transform="rotate(0,206.5,150.5)"
      />
      <rect
        x="21"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,24.5,157.5)"
      />
      <rect
        x="35"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,38.5,157.5)"
      />
      <rect
        x="42"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,45.5,157.5)"
      />
      <rect
        x="56"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,59.5,157.5)"
      />
      <rect
        x="70"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,73.5,157.5)"
      />
      <rect
        x="77"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,80.5,157.5)"
      />
      <rect
        x="84"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,87.5,157.5)"
      />
      <rect
        x="98"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,101.5,157.5)"
      />
      <rect
        x="112"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,115.5,157.5)"
      />
      <rect
        x="119"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,122.5,157.5)"
      />
      <rect
        x="133"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,136.5,157.5)"
      />
      <rect
        x="140"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,143.5,157.5)"
      />
      <rect
        x="147"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,150.5,157.5)"
      />
      <rect
        x="154"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,157.5,157.5)"
      />
      <rect
        x="161"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,164.5,157.5)"
      />
      <rect
        x="168"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,171.5,157.5)"
      />
      <rect
        x="175"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,178.5,157.5)"
      />
      <rect
        x="182"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,185.5,157.5)"
      />
      <rect
        x="196"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,199.5,157.5)"
      />
      <rect
        x="203"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,206.5,157.5)"
      />
      <rect
        x="210"
        y="154"
        width="7"
        height="7"
        transform="rotate(0,213.5,157.5)"
      />
      <rect
        x="70"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,73.5,164.5)"
      />
      <rect
        x="77"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,80.5,164.5)"
      />
      <rect
        x="91"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,94.5,164.5)"
      />
      <rect
        x="98"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,101.5,164.5)"
      />
      <rect
        x="112"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,115.5,164.5)"
      />
      <rect
        x="126"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,129.5,164.5)"
      />
      <rect
        x="133"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,136.5,164.5)"
      />
      <rect
        x="154"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,157.5,164.5)"
      />
      <rect
        x="182"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,185.5,164.5)"
      />
      <rect
        x="189"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,192.5,164.5)"
      />
      <rect
        x="203"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,206.5,164.5)"
      />
      <rect
        x="210"
        y="161"
        width="7"
        height="7"
        transform="rotate(0,213.5,164.5)"
      />
      <rect
        x="84"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,87.5,171.5)"
      />
      <rect
        x="91"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,94.5,171.5)"
      />
      <rect
        x="112"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,115.5,171.5)"
      />
      <rect
        x="140"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,143.5,171.5)"
      />
      <rect
        x="147"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,150.5,171.5)"
      />
      <rect
        x="154"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,157.5,171.5)"
      />
      <rect
        x="168"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,171.5,171.5)"
      />
      <rect
        x="182"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,185.5,171.5)"
      />
      <rect
        x="196"
        y="168"
        width="7"
        height="7"
        transform="rotate(0,199.5,171.5)"
      />
      <rect
        x="70"
        y="175"
        width="7"
        height="7"
        transform="rotate(0,73.5,178.5)"
      />
      <rect
        x="112"
        y="175"
        width="7"
        height="7"
        transform="rotate(0,115.5,178.5)"
      />
      <rect
        x="133"
        y="175"
        width="7"
        height="7"
        transform="rotate(0,136.5,178.5)"
      />
      <rect
        x="154"
        y="175"
        width="7"
        height="7"
        transform="rotate(0,157.5,178.5)"
      />
      <rect
        x="182"
        y="175"
        width="7"
        height="7"
        transform="rotate(0,185.5,178.5)"
      />
      <rect
        x="210"
        y="175"
        width="7"
        height="7"
        transform="rotate(0,213.5,178.5)"
      />
      <rect
        x="91"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,94.5,185.5)"
      />
      <rect
        x="98"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,101.5,185.5)"
      />
      <rect
        x="126"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,129.5,185.5)"
      />
      <rect
        x="154"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,157.5,185.5)"
      />
      <rect
        x="161"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,164.5,185.5)"
      />
      <rect
        x="168"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,171.5,185.5)"
      />
      <rect
        x="175"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,178.5,185.5)"
      />
      <rect
        x="182"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,185.5,185.5)"
      />
      <rect
        x="189"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,192.5,185.5)"
      />
      <rect
        x="196"
        y="182"
        width="7"
        height="7"
        transform="rotate(0,199.5,185.5)"
      />
      <rect
        x="70"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,73.5,192.5)"
      />
      <rect
        x="77"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,80.5,192.5)"
      />
      <rect
        x="84"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,87.5,192.5)"
      />
      <rect
        x="105"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,108.5,192.5)"
      />
      <rect
        x="112"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,115.5,192.5)"
      />
      <rect
        x="133"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,136.5,192.5)"
      />
      <rect
        x="147"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,150.5,192.5)"
      />
      <rect
        x="182"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,185.5,192.5)"
      />
      <rect
        x="189"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,192.5,192.5)"
      />
      <rect
        x="196"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,199.5,192.5)"
      />
      <rect
        x="203"
        y="189"
        width="7"
        height="7"
        transform="rotate(0,206.5,192.5)"
      />
      <rect
        x="70"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,73.5,199.5)"
      />
      <rect
        x="77"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,80.5,199.5)"
      />
      <rect
        x="84"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,87.5,199.5)"
      />
      <rect
        x="91"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,94.5,199.5)"
      />
      <rect
        x="112"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,115.5,199.5)"
      />
      <rect
        x="140"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,143.5,199.5)"
      />
      <rect
        x="154"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,157.5,199.5)"
      />
      <rect
        x="175"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,178.5,199.5)"
      />
      <rect
        x="189"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,192.5,199.5)"
      />
      <rect
        x="210"
        y="196"
        width="7"
        height="7"
        transform="rotate(0,213.5,199.5)"
      />
      <rect
        x="70"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,73.5,206.5)"
      />
      <rect
        x="98"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,101.5,206.5)"
      />
      <rect
        x="105"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,108.5,206.5)"
      />
      <rect
        x="119"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,122.5,206.5)"
      />
      <rect
        x="168"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,171.5,206.5)"
      />
      <rect
        x="182"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,185.5,206.5)"
      />
      <rect
        x="189"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,192.5,206.5)"
      />
      <rect
        x="203"
        y="203"
        width="7"
        height="7"
        transform="rotate(0,206.5,206.5)"
      />
      <rect
        x="77"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,80.5,213.5)"
      />
      <rect
        x="91"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,94.5,213.5)"
      />
      <rect
        x="98"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,101.5,213.5)"
      />
      <rect
        x="105"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,108.5,213.5)"
      />
      <rect
        x="112"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,115.5,213.5)"
      />
      <rect
        x="140"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,143.5,213.5)"
      />
      <rect
        x="147"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,150.5,213.5)"
      />
      <rect
        x="168"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,171.5,213.5)"
      />
      <rect
        x="175"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,178.5,213.5)"
      />
      <rect
        x="203"
        y="210"
        width="7"
        height="7"
        transform="rotate(0,206.5,213.5)"
      />
    </g>
  </svg>
  );
}
  
export default QRcode;